
import TelaExibirDetalhes from '../TelaExibirDetalhes.vue';

  export default {
    name: "tela-exibir-sucesso-banco-b-v",
    data: () => ({
      detalhe_proposta: false,
    }),
    methods: {
      async openWhatsapp() {
        const url =
          "https://api.whatsapp.com/send?phone=558008782752&text=Quero%20contratar%20antecipa%C3%A7%C3%A3o%20do%20FGTS%20para%20o%20meu%20CPF%3A%20" +
          this.proposta?.cpf;
        window.open(url, "_blank");
      },
      async openProposta() {
        const url = this.proposta?.Url;
        window.open(url, "_blank");
      },
    },
    props: [
      "pacote_selecionado",
      "proposta"
    ],
    components: { TelaExibirDetalhes }
};
