import { render, staticRenderFns } from "./index.vue?vue&type=template&id=4bbfd4aa&scoped=true"
import script from "./index.vue?vue&type=script&lang=js"
export * from "./index.vue?vue&type=script&lang=js"
import style0 from "./index.vue?vue&type=style&index=0&id=4bbfd4aa&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4bbfd4aa",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ContentWrapper: require('/codebuild/output/src2039782690/src/components/ContentWrapper.vue').default,BankingPartnersList: require('/codebuild/output/src2039782690/src/components/BankingPartnersList.vue').default,TelaExibirSuccessoSafra: require('/codebuild/output/src2039782690/src/components/fluxo-ok/AutoContratacao/Safra/TelaExibirSuccessoSafra.vue').default,TelaExibirSuccessoFacta: require('/codebuild/output/src2039782690/src/components/fluxo-ok/AutoContratacao/Facta/TelaExibirSuccessoFacta.vue').default,TelaExibirSucessoBancoPan: require('/codebuild/output/src2039782690/src/components/fluxo-ok/AutoContratacao/BancoPan/TelaExibirSucessoBancoPan.vue').default,TelaExibirSucessoBancoBV: require('/codebuild/output/src2039782690/src/components/fluxo-ok/AutoContratacao/BancoBV/TelaExibirSucessoBancoBV.vue').default,TelaExibirSucessoIcred: require('/codebuild/output/src2039782690/src/components/fluxo-ok/AutoContratacao/ICred/TelaExibirSucessoIcred.vue').default})
